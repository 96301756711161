<template>
  <div>
    <i class="fas fa-user-plus"></i>
    <strong class="text-black">
      {{ task_creator ? task_creator.name : 'Нет автора' }}
    </strong>
    <span v-if="task_updated_date" class="font-small-xs text-right" style="display: block;">
      {{ task_updated_date }}
    </span>

    <hr class="mt-1 mb-1"/>
  </div>
</template>

<script>
export default {
  name: 'task-item-creator-block',

  props: {
    task_creator: {
      type: Object|null,
    },
    task_updated_date: {
      type: String|null,
    },
  },
}
</script>

<style scoped>

</style>