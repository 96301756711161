import { render, staticRenderFns } from "./creator-block.vue?vue&type=template&id=57d21231&scoped=true&"
import script from "./creator-block.vue?vue&type=script&lang=js&"
export * from "./creator-block.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d21231",
  null
  
)

export default component.exports